import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"

const TemplatePage = () => {
    return (
        <div>
            {HeaderBar()}

            {FooterBar()}
        </div>
    )
}

export default TemplatePage